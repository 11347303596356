<template>
  <div id="Forms">
    <div class="money">
      本活动需支付0.01元
    </div>
    <van-form @submit="onSubmit">
      <van-field
        v-model="name"
        required
        name="姓名"
        label="姓名"
        placeholder="请输入姓名"
        :rules="[{ required: true, message: '请填写姓名' }]"
      />
      <van-field
        v-model="phone"
        required
        name="手机号"
        label="手机号"
        placeholder="请输入手机号"
        :rules="[{ required: true, message: '请填写手机号' }]"
      />
      <van-field
        v-model="wechat"
        required
        name="微信号"
        label="微信号"
        placeholder="请输入微信号"
        :rules="[{ required: true, message: '请填写微信号' }]"
      />
      <van-field
        v-model="company"
        name="工作单位"
        label="工作单位"
        placeholder="请输入工作单位"
        :rules="[{ required: false, message: '请填写工作单位' }]"
      />
      <van-field
        v-model="job"
        name="职位名称"
        label="职位名称"
        placeholder="请输入职位名称"
        :rules="[{ required: false, message: '请填写职位名称' }]"
      />
      <van-field
        v-model="email"
        name="邮箱地址"
        label="邮箱地址"
        placeholder="请输入邮箱地址"
        :rules="[{ required: false, message: '请填写邮箱地址' }]"
      />
   
      <div class="submit">
        <div :class="{'gray':!name || !phone ||!wechat}" @click="onSubmit">下一步</div>
      </div>
    </van-form>
    
    
  </div>
</template>
<script>
export default {
  name: 'Forms',
   data(){
    return{
      activityId:1,
      name:'',
      phone:'',
      wechat:'',
      company:'',
      job:'',
      email:'',
      isPay:0
    }
  },
  mounted(){
    this.activityId = this.$route.query.activityId
  },
  methods:{
    onSubmit(){
      if(!this.name){
        this.$toast('姓名不能为空')
        return
      }
      if(!this.wechat){
        this.$toast('微信号不能为空')
        return
      }
      if(!this.phone){
        this.$toast('手机号不能为空')
        return
      }else {
        var phone = this.phone
        if(!(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(phone))){ 
          this.$toast('手机号码有误，请重填')
          return false; 
        } 
      }
      this.axios({
        method: 'POST',
        url: '/activity/activityApply',
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        data:{
          activityId:this.activityId,
          name:this.name,
          phone:this.phone,
          wechat:this.wechat,
          company:this.company,
          job:this.job,
          email:this.email,
        }
      }).then((res) => {
        if(res.data.code == 0){
          this.product_id = res.data.data.id
          this.prepay()
        }else{
          this.$toast(res.data.message)
        }
      })
    },
    prepay(){
      if( !this.isPay ){
        this.isPay = 1
        this.axios({
          method: 'POST',
          url: '/payment/prepay',
          headers: {
            'Authorization': `Bearer ${this.Global.getCookie('token')}` 
          },
          data: {
            productId: this.product_id,
            transmit:''
          }
        }).then( (res) => {
          if(res.data.code == 0){
            if(res.data.data.redirect){
              //免费
              this.$toast('购买成功！')
              setTimeout(() => {
                this.$router.push('/SignUpEnd?type=c&activityId='+this.activityId)
              }, 1000)
            }else{
              this.callPay(res.data.data)
            }
          }else{
            this.isPay = 0
            this.$toast(res.data.message)
          }
        })
      }
    },
    // 微信调起支付
    callPay(res){
      if (typeof WeixinJSBridge == "undefined"){
        if( document.addEventListener ){
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(res), false);
        }else if (document.attachEvent){
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(res)); 
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(res));
        }
      }else{
        this.onBridgeReady(res);
      }
    },
    onBridgeReady(data){
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', data.config,
        (res) => {
          if(res.err_msg == "get_brand_wcpay_request:ok" ){
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            this.$toast('购买成功！')
            this.$router.push('/Home?showQrCode=1')
          }else if(res.err_msg == "get_brand_wcpay_request:cancel"){
            this.isPay = 0
          }else if(res.err_msg == "get_brand_wcpay_request:fail"){
            this.isPay = 0
            this.$toast('支付失败')
          }
      }); 
    },
  }
}
</script>
<style lang="less" scoped>
#Forms{
  padding-top: 0.15rem;
  .money{
    text-align: center;
    color: #F6F7FD;
    margin-bottom: 0.1rem;
  }
  .submit{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.35rem;
    .gray{
      background: #9091A3;
    }
    div{
      width: 2.37rem;
      height: 0.4rem;
      line-height: 0.4rem;
      border-radius: 0.4rem;
      text-align: center;
      background: #3890FF;
      color: #F6F7FD;
    }
  }
}
</style>